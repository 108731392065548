import house from './HouseStore';
import search from './SearchStore';
import contactUsStore from './ContactUsStore'
import booking from './BookingStore'
import config from './ConfigStore'
import customer from './CustomerStore'

export default {
  house,
  search,
  contactUsStore,
  booking,
  config,
  customer
};

import http from '../utils/http'

export class CustomerStore {
  async sendOTP(emailOrPhone) {
    try {
      const response = await http.post(`/customers/login`, { email_or_phone: emailOrPhone });
      if (response) {
        return response
      }
    } catch (error) {
      throw error;
    }
  }
  async update(data) {
    try {
      const response = await http.put(`/customers`, data);
      if (response) {
        return response
      }
    } catch (error) {
      throw error;
    }
  }

}

const customerStore = new CustomerStore()
export default customerStore

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/AboutUs/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-booking-detail-js": () => import("./../../../src/pages/Booking/detail.js" /* webpackChunkName: "component---src-pages-booking-detail-js" */),
  "component---src-pages-booking-index-js": () => import("./../../../src/pages/Booking/index.js" /* webpackChunkName: "component---src-pages-booking-index-js" */),
  "component---src-pages-bookings-index-js": () => import("./../../../src/pages/Bookings/index.js" /* webpackChunkName: "component---src-pages-bookings-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/ContactUs/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-customer-review-index-js": () => import("./../../../src/pages/CustomerReview/index.js" /* webpackChunkName: "component---src-pages-customer-review-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-house-index-js": () => import("./../../../src/pages/House/index.js" /* webpackChunkName: "component---src-pages-house-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/Profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */)
}

